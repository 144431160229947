import { FC, useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { LS_AUTH_KEY } from '@common/config/consts';

const Impersonate: FC = () => {
  const [_params] = useSearchParams();
  const params = useMemo(() => Object.fromEntries(_params.entries()), [_params]);

  if (!params.token || !params.email) {
    throw new Error('Unauthorized');
  }

  // Use administration token
  localStorage.setItem(LS_AUTH_KEY, params.token);

  // Simulate impersonation by adding the email in req headers
  localStorage.setItem('impersonate', params.email);

  return <Navigate to="/" />;
};

export default Impersonate;
