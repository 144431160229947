import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Center, HStack, Link, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import ValueField from '@common/components/ValueField';
import { safeDateFormat } from '@common/utils/formatters';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';

const Detail: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(EXPIRATION_QUERY, {
    variables: {
      id: parseInt(id!, 10),
    },
  });

  if (loading) {
    return <Loading h="full" />;
  }

  if (error) {
    return <Center h="full">Si è verificato errore imprevisto</Center>;
  }

  if (!data?.expiration) {
    return (
      <Center h="full">
        <VStack spacing={4}>
          <Text>Questa scadenza non esiste o è stata rimossa</Text>
          <Link color="primary.700" href={ROUTE.EXPIRATIONS}>
            Torna alla lista
          </Link>
        </VStack>
      </Center>
    );
  }

  const { expiration } = data;

  return (
    <Stack direction="column" spacing={8} p={6}>
      <HStack spacing={4} justify="space-between">
        <Text fontWeight="medium" fontSize="xl">
          Dettaglio scadenza
        </Text>
      </HStack>

      <SimpleGrid columns={2} spacing={4} p={2} w="600px">
        <Text fontWeight="medium">Titolo</Text>
        <ValueField>{expiration.title}</ValueField>

        <Text fontWeight="medium">Data</Text>
        <ValueField>{safeDateFormat(expiration.date, 'd MMMM yyyy')}</ValueField>

        <Text fontWeight="medium">Notifica</Text>
        <ValueField>{expiration.notify ? 'Si' : 'No'}</ValueField>
      </SimpleGrid>
    </Stack>
  );
};

const EXPIRATION_QUERY = graphql(`
  query ExpirationDetailEmployee($id: Int!) {
    expiration(id: $id) {
      id
      date
      notify
      title
      staff {
        id
        email
        name
      }
    }
  }
`);

export default Detail;
