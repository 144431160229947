import React, { FC, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { Center, CenterProps, CircularProgress, Image, Text } from '@chakra-ui/react';

interface Props extends CenterProps {
  avatarName?: string | null;
  avatarSrc?: string | null;
  loading?: boolean;
}
const AvatarSafe: FC<Props> = ({ avatarName, avatarSrc, loading, ...props }) => {
  const [safeAvatarSrc, setSafeAvatarSrc] = useState<string | null | undefined>(avatarSrc);

  useEffect(() => {
    setSafeAvatarSrc(avatarSrc);
  }, [avatarSrc]);

  return (
    <Center w="45px" h="45px" borderRadius="50%" {...props}>
      <If condition={loading}>
        <Then>
          <CircularProgress size="30px" isIndeterminate color="primary.500" />
        </Then>
        <Else>
          <If condition={safeAvatarSrc}>
            <Then>
              <Image
                src={safeAvatarSrc || undefined}
                referrerPolicy="no-referrer"
                alt={avatarName || undefined}
                borderRadius="50%"
                w="100%"
                h="100%"
                minW="45px"
                minH="45px"
                onError={() => setSafeAvatarSrc(null)}
              />
            </Then>
            <Else>
              <Center w="100%" h="100%" bg="primary.500" borderRadius="50%">
                <Text fontSize="lg" fontWeight="bold" color="white" textAlign="center">
                  {avatarName?.charAt(0).toUpperCase()}
                </Text>
              </Center>
            </Else>
          </If>
        </Else>
      </If>
    </Center>
  );
};

export default AvatarSafe;
