import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import { ActivityInput } from '@gql/graphql';
import Form from './Form';

const Create: FC = () => {
  const navigate = useNavigate();
  const [createActivity] = useMutation(ACTIVITY_CREATE_MUTATION);

  const handleSubmit = async (values: ActivityInput) => {
    const res = await createActivity({
      variables: {
        data: values,
      },
    });

    if (!res?.data) {
      throw new Error('Si è verificato un errore imprevisto');
    }

    if (res.data.activityCreate.errors.length > 0) {
      return res.data.activityCreate.errors;
    } else {
      toast.success('Attività creata con successo');
      navigate(ROUTE.ACTIVITIES_DETAIL.replace(':id', res.data.activityCreate.object!.id!.toString()));
    }
  };

  return <Form onSubmit={handleSubmit} title="Crea attività" />;
};

const ACTIVITY_CREATE_MUTATION = graphql(`
  mutation ActivityCreate($data: ActivityInput!) {
    activityCreate(data: $data) {
      errors
      object {
        id
      }
    }
  }
`);

export default Create;
