import { FC } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Button, HStack, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { graphql } from '@gql/gql';

interface Props {
  activityId: number;
  onDelete: () => void;
}

const DeleteButton: FC<Props> = ({ activityId, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteActivity, { loading }] = useMutation(ACTIVITY_DELETE_MUTATION, {
    onCompleted: onDelete,
  });

  const handleDelete = () => {
    deleteActivity({
      variables: {
        activityDeleteId: activityId,
      },
    })
      .then(() => {
        toast.success('Attività eliminato con successo');
      })
      .catch(() => {
        toast.error("Si è verificato un errore durante l'eliminazione");
      });
  };

  return (
    <>
      <Button colorScheme="red" variant="outline" size="sm" leftIcon={<Icon as={FiTrash2} />} onClick={onOpen}>
        Elimina
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sei sicuro di voler eliminare questa attività?</ModalHeader>
          <ModalBody>
            <Text>Questa azione è irreversibile.</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justify="flex-end">
              <Button variant="ghost" colorScheme="blackAlpha" onClick={onClose}>
                Annulla
              </Button>
              <Button colorScheme="red" variant="outline" onClick={handleDelete} isLoading={loading}>
                Conferma
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ACTIVITY_DELETE_MUTATION = graphql(`
  mutation ActivityDeleteEmployee($activityDeleteId: Int!) {
    activityDelete(id: $activityDeleteId) {
      errors
      object {
        id
      }
    }
  }
`);

export default DeleteButton;
