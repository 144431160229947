import { useMemo, useState } from 'react';
import qs from 'qs';
import cleanFalsy from '../utils/cleanFalsy';

export interface UseParamStateOptions<T> {
  /* Params to parse  */
  fields?: Array<string>;

  /* Convert parsed data to correct types. Executed at first parse only */
  transform?: (obj: Record<keyof T, string>) => Partial<T>;

  /* Validate parsed data after transform. Executed at first parse only */
  validate?: (obj: any) => obj is T;
}

/**
 * Create a state controller stored in the URL search query.
 * @param initialState Initial filters
 */
const useParamState = <T,>(initialState?: T, options?: UseParamStateOptions<T>): [T | undefined, (state: T) => void] => {
  /* Try to parse state from search query on mount or fallback to initialState */
  const _initialState = useMemo(() => {
    // Parse state from URL
    let parsed = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
      decoder(value) {
        if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value);
        }

        if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(value)) {
          return new Date(value);
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        };
        if (value in keywords) {
          return keywords[value];
        }

        if (typeof value === 'string') {
          return decodeURIComponent(value);
        }

        return value;
      },
    }) as any;

    const paramsExists = !!Object.keys(parsed).length;

    if (!paramsExists) {
      return initialState;
    }

    // Filter the fields specified in options
    if (options?.fields) {
      parsed = Object.fromEntries(Object.entries(parsed).filter(([key]) => options.fields!.includes(key)));
    }

    // Apply transform fn if provided
    if (options?.transform) {
      try {
        parsed = { ...parsed, ...options.transform(parsed) };
      } catch (error) {
        console.error('Transform function thrown an error. Fallback to initialState.:', error);
        return initialState;
      }
    }

    // Apply validation if provided
    if (options?.validate) {
      if (!options?.validate(parsed)) {
        console.error('Invalid state in search query. Fallback to initialState.');
        return initialState;
      }
    }

    return parsed as T | undefined;
  }, []);

  const [state, setState] = useState<T | undefined>(_initialState);

  const setStatePreprocess = (nextState: T) => {
    // Get the old params to avoid missing unrelated params
    const oldParams = qs.parse(window.location.search.split('?')[1], {
      ignoreQueryPrefix: true,
    });

    // Merge the current params with the new ones to avoid miss other params
    // Clean & sort properties
    const newState = cleanFalsy({
      ...oldParams,
      ...nextState,
    });

    const serializedParams = qs.stringify(newState, {
      encode: false,
      addQueryPrefix: true,
      encodeValuesOnly: true,
      serializeDate: (date) => date.toISOString(),
    });

    if (serializedParams.length >= 2047) {
      console.warn(
        'Your serialized state has exceeded the maximum length for some browsers. Consider removing unnecessary info from it to reduce its size',
      );
    }

    window.history.replaceState(null, '', window.location.pathname + serializedParams);

    setState(nextState);
  };

  return [state, setStatePreprocess];
};

export default useParamState;
