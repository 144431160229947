import { FC, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { Service } from '@gql/graphql';
import ServiceAutocompleteField from './Field';

export type ServiceSearch = Pick<Service, 'id' | 'name'>;

interface Props extends AutocompleteProps {
  value?: ServiceSearch | number | null;
  onChange: (value: ServiceSearch | null) => void;
}

const Loader: FC<Props> = ({ value, ...props }) => {
  const isValueID = useMemo(() => typeof value === 'number', [value]);
  const [internalValue, setInternalValue] = useState<ServiceSearch | null | undefined>(isValueID ? undefined : (value as ServiceSearch));

  const { loading, error } = useQuery(SERVICE_QUERY, {
    variables: {
      // @ts-expect-error unreachable if undefined
      id: value,
    },
    fetchPolicy: 'network-only',
    skip: props.isDisabled || !isValueID || typeof internalValue !== 'undefined',
    onCompleted: (data) => setInternalValue(data?.service ? { id: data.service.id, name: data.service.name! } : null),
  });

  const handleChange = (_value: ServiceSearch | null) => {
    setInternalValue(_value);
    props.onChange(_value);
  };

  if (error) throw error;

  return <ServiceAutocompleteField isLoading={loading} value={internalValue} {...props} onChange={handleChange} />;
};

const SERVICE_QUERY = graphql(`
  query ServiceSearchDetail($id: Int!) {
    service(id: $id) {
      id
      name
    }
  }
`);

export default Loader;
