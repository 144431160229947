import { FC } from 'react';
import { formatDuration } from 'date-fns';
import { FiEdit } from 'react-icons/fi';
import { When } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Center, HStack, Icon, Link, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import ValueField from '@common/components/ValueField';
import { safeDateFormat } from '@common/utils/formatters';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import DeleteButton from './DeleteButton';

const Detail: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(ACTIVITY_QUERY, {
    variables: {
      id: parseInt(id!, 10),
    },
  });

  if (loading) {
    return <Loading h="full" />;
  }

  if (error) {
    return <Center h="full">Si è verificato errore imprevisto</Center>;
  }

  if (!data?.activity) {
    return (
      <Center h="full">
        <VStack spacing={4}>
          <Text>Questa attività non esiste o è stata rimossa</Text>
          <Link color="primary.700" href={ROUTE.ACTIVITIES}>
            Torna alla lista
          </Link>
        </VStack>
      </Center>
    );
  }

  const { activity } = data;
  const startDate = new Date(activity.date + 'T' + activity.startTime);
  const endDate = new Date(activity.date + 'T' + activity.endTime);

  return (
    <Stack direction="column" spacing={8} p={6}>
      <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} justify="space-between">
        <Text fontWeight="medium" fontSize="xl">
          Dettaglio attività del {safeDateFormat(activity.date, 'd MMMM yyyy')}
        </Text>

        <HStack spacing={2}>
          <When condition={activity.isEditable}>
            <Button
              colorScheme="primary"
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FiEdit} />}
              onClick={() => navigate(ROUTE.ACTIVITIES_EDIT.replace(':id', id!))}
            >
              Modifica
            </Button>
            <DeleteButton activityId={activity.id} onDelete={() => navigate(ROUTE.ACTIVITIES)} />
          </When>
        </HStack>
      </Stack>

      <SimpleGrid columns={2} spacing={4} p={2} gridTemplateColumns={{ base: '1fr', sm: '1fr 2fr' }}>
        <Text fontWeight="medium">Cantiere</Text>
        <ValueField>{activity.service?.fullName}</ValueField>

        {/* <Text fontWeight="medium">Committente</Text>
        <ValueField>{activity.service?.client?.name}</ValueField> */}

        <Text fontWeight="medium">Utente</Text>
        <ValueField>{activity.user?.name}</ValueField>
        {/*
        <Text fontWeight="medium">Settore</Text>
        <ValueField>{activity.service?.sectorCode}</ValueField> */}

        <Text fontWeight="medium">Data</Text>
        <ValueField>{safeDateFormat(activity.date, 'd MMMM yyyy')}</ValueField>

        <Text fontWeight="medium">Orario di inizio</Text>
        <ValueField>{safeDateFormat(startDate, 'H:mm')}</ValueField>

        <Text fontWeight="medium">Orario di fine</Text>
        <ValueField>{activity.startTime === activity.endTime ? '' : safeDateFormat(endDate, 'H:mm')}</ValueField>

        <Text fontWeight="medium">Totale ore</Text>
        <ValueField>
          {formatDuration(
            {
              hours: Math.floor(activity.totalMinutes / 60),
              minutes: Math.floor(activity.totalMinutes % 60),
            },
            { format: ['hours', 'minutes'] },
          )}
        </ValueField>

        <Text fontWeight="medium">Totale ore (centesimi)</Text>
        <ValueField>
          {(activity.totalMinutes / 60).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </ValueField>
      </SimpleGrid>
    </Stack>
  );
};

const ACTIVITY_QUERY = graphql(`
  query ActivityDetailEmployee($id: Int!) {
    activity(id: $id) {
      id
      date
      isEditable
      endTime
      startTime
      totalMinutes
      service {
        id
        name
        fullName
        client {
          id
          name
        }
        sectorCode
      }
      user {
        name
      }
    }
  }
`);

export default Detail;
