import { FC } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { When } from 'react-if';
import { useLocation, useNavigate } from 'react-router';
import { GenericFunction } from '@/types';
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import Logo from '../../assets/img/logo.jpg';
import AvatarSafe from './Avatar';

export interface MenuElement {
  name: string;
  path?: string; // ROUTE
  onClick?: GenericFunction;
}

interface Props {
  navElements: Array<MenuElement>;
  title?: string;

  userMenuElements?: Array<MenuElement>;

  loading?: boolean;
  avatarName?: string | null;
  avatarSrc?: string | null;
}

const isActiveLocation = (pathname: string, route?: string) => {
  if (!route) return false;

  const _route = route.replace(':id', '');
  return pathname.includes(_route);
};

const Navbar: FC<Props> = ({ navElements, userMenuElements, title, loading, avatarName, avatarSrc }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack spacing={2} px={{ base: 3, md: 5 }} py={1} borderColor="blackAlpha.300" borderBottomWidth={1}>
      <HStack justify="start" spacing={6} flexGrow={1}>
        <Image src={Logo} alt="Logo" height="50px" />

        <When condition={title}>
          <Text
            display={{
              base: 'none',
              md: 'block',
            }}
            fontWeight="medium"
            fontSize="sm"
          >
            {title}
          </Text>
        </When>
      </HStack>

      <HStack
        display={{
          base: 'none',
          md: 'flex',
        }}
        spacing={4}
      >
        {navElements.map(({ name, path, onClick }) => (
          <Box
            key={path}
            onClick={() => {
              if (onClick) onClick();
              if (path) navigate(path);
            }}
          >
            <Button variant="link" fontSize="sm" fontWeight="medium" color={isActiveLocation(location.pathname, path) ? 'primary.700' : 'inherit'}>
              {name}
            </Button>
          </Box>
        ))}
      </HStack>

      <Box
        display={{
          base: 'none',
          md: 'block',
        }}
        p={3}
      >
        <Menu>
          <MenuButton>
            <AvatarSafe avatarName={avatarName} avatarSrc={avatarSrc} loading={loading} />
          </MenuButton>
          <MenuList>
            <Box p={4} mt="-8px" mb="8px" backgroundColor="gray.50">
              <Text fontWeight="normal">{avatarName}</Text>
            </Box>
            {userMenuElements?.map(({ name, onClick, path }) => (
              <MenuItem
                key={name}
                onClick={() => {
                  if (onClick) onClick();
                  if (path) navigate(path);
                }}
              >
                {name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>

      <Box
        display={{
          base: 'block',
          md: 'none',
        }}
      >
        <IconButton aria-label="Menu" icon={<FiMenu />} variant="outline" onClick={onOpen} />
      </Box>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>{title}</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              {navElements.map(({ name, path, onClick }) => (
                <Box
                  key={path}
                  onClick={() => {
                    if (onClick) onClick();
                    onClose();
                    if (path) navigate(path);
                  }}
                >
                  <Button
                    variant="link"
                    fontSize="sm"
                    fontWeight="medium"
                    color={isActiveLocation(location.pathname, path) ? 'primary.700' : 'inherit'}
                  >
                    {name}
                  </Button>
                </Box>
              ))}
            </VStack>

            <Box mt={6} mb={2}>
              <Divider />
            </Box>

            <Box mt={4} mb={2}>
              <Text fontWeight="medium" textAlign="center">
                {avatarName}
              </Text>
            </Box>

            <VStack spacing={4}>
              {userMenuElements?.map(({ name, onClick, path }) => (
                <Box
                  key={name}
                  onClick={() => {
                    if (onClick) onClick();
                    onClose();
                    if (path) navigate(path);
                  }}
                >
                  <Button variant="link" fontSize="sm" fontWeight="medium">
                    {name}
                  </Button>
                </Box>
              ))}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <IconButton aria-label="Chiudi" icon={<FiX />} variant="ghost" onClick={onClose} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
};

export default Navbar;
