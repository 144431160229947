import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import BaseAutocomplete from '@components/BaseAutocomplete';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { ServiceUserSort } from '@gql/graphql';
import { ServiceUserSearch } from '.';

const AUTOCOMPLETE_ELEMENTS = 5;

interface Props extends AutocompleteProps {
  serviceId: number;

  value?: ServiceUserSearch | null;
  onChange: (value: ServiceUserSearch | null) => void;

  isLoading?: boolean;
}

const ServiceUserAutocompleteField: FC<Props> = ({ value, onChange, serviceId, ...props }) => {
  const [search, setSearch] = useState('');

  const { data, loading, error } = useQuery(SERVICE_USER_LIST_QUERY, {
    variables: {
      filters: {
        name: search,
        serviceId: serviceId,
      },
      pagination: {
        pageSize: AUTOCOMPLETE_ELEMENTS,
      },
      sort: ServiceUserSort.Name,
    },
    skip: props.isDisabled
  });

  if (error) throw error;

  return (
    <BaseAutocomplete<number>
      value={value ? { value: value.id, label: value.name || '' } : null}
      onChange={(_value) => onChange(_value ? { id: _value.value, name: _value.label } : null)}
      searchText={search}
      onSearchChange={setSearch}
      options={data?.serviceUserList.objects.map(({ id, name }) => ({
        value: id,
        label: name,
      }))}
      notFoundText={'Nessun utente trovato'}
      isOptionsLoading={loading}
      {...props}
    />
  );
};

const SERVICE_USER_LIST_QUERY = graphql(`
  query ServiceUserListSearch($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {
    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {
      objects {
        id
        name
      }
      count
      numPages
    }
  }
`);

export default ServiceUserAutocompleteField;
