import { KeyboardEventHandler, useRef, useState } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { When } from 'react-if';
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Loading from '@components/Loading';
import useDebounce from '@hooks/useDebounce';
import AutocompleteOption from './AutocompleteOption';
import FakeInput from './FakeInput';
import { BaseAutocompleteProps } from './types';

const BaseAutocompleteField = <V extends string | number>({
  error,
  label,
  helperText,
  isDisabled,

  options = [],
  isOptionsLoading,

  value: selectedOption,
  onChange,

  freeSolo,
  onAddNewOption,
  addNewOptionText = 'Add new option',

  searchText,
  onSearchChange,

  // multiple = false,
  debounceMs = 500,
  notFoundText = 'No results found',
  ...props // FormControlProps
}: BaseAutocompleteProps<V>) => {
  const [innerSearchText, setInnerSearchText] = useState<string>(searchText || selectedOption?.label || '');
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const InputContainerRef = useRef<HTMLDivElement>(null);
  const InputRef = useRef<HTMLInputElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  useDebounce(innerSearchText, debounceMs, onSearchChange);

  const handleKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!isOpen) {
      onOpen();
    }

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (activeIndex + 1 < options.length) {
          setActiveIndex(activeIndex + 1);
        } else {
          setActiveIndex(options.length - 1);
        }
        break;
      }

      case 'ArrowUp': {
        e.preventDefault();
        if (activeIndex - 1 >= 0) {
          setActiveIndex(activeIndex - 1);
        } else {
          setActiveIndex(0);
        }
        break;
      }
      case 'Enter': {
        e.preventDefault();
        if (options.length <= 0) {
          break;
        }

        if (options[activeIndex]) {
          onChange(options[activeIndex]);
          setInnerSearchText(options[activeIndex].label);
        } else {
          setInnerSearchText('');
        }

        onClose();
        resetActive();
        break;
      }

      case 'Escape': {
        e.preventDefault();
        if (!freeSolo) {
          setInnerSearchText(selectedOption?.label || '');
        }
        resetActive();
        onClose();
      }
    }
  };

  const resetActive = async () => {
    // wait for the popover to close
    await new Promise((resolve) => setTimeout(resolve, 200));
    setActiveIndex(0);
  };

  return (
    <FormControl isInvalid={!!error} {...props}>
      <When condition={!!label}>
        <FormLabel color={isDisabled ? 'blackAlpha.500' : 'inherit'} htmlFor={label}>
          {label}
        </FormLabel>
      </When>

      <Popover
        autoFocus={false}
        isOpen={!isDisabled && isOpen}
        onOpen={onOpen}
        onClose={() => {
          resetActive();
          onClose();
        }}
      >
        <PopoverAnchor>
          <FakeInput
            ref={InputContainerRef}
            borderWidth={error ? 2 : 1}
            borderColor={isDisabled ? 'blackAlpha.200' : error ? 'red.400' : 'gray.200'}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            boxShadow={isOpen ? '0px 0px 0px 2px var(--chakra-colors-primary-500)' : 'none'}
            _hover={{
              borderColor: isDisabled ? 'gray.200' : 'gray.300',
            }}
          >
            <Box flexGrow={1}>
              <Input
                ref={InputRef}
                w="full"
                autoComplete="off"
                px={3}
                variant="unstyled"
                value={innerSearchText}
                isDisabled={isDisabled}
                onChange={(e) => {
                  if (freeSolo) {
                    onChange({ value: e.target.value as V, label: e.target.value });
                  }
                  setInnerSearchText(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                onFocus={onOpen}
                onBlur={() => {
                  if (!freeSolo) {
                    setInnerSearchText(selectedOption?.label || '');
                  }
                  resetActive();
                  onClose();
                }}
              />
              {/* todo: multiple mode
                    {options.map((option) => {
                      return (
                        <Tag key={option} py={1} px={2} m={1} size="md">
                          <HStack>
                            <Text>{option?.label}</Text>
                            <Icon
                              as={FiX}
                              name="cancel"
                              cursor="pointer"
                              fontSize="xl"
                              color="gray.500"
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(value.filter((val) => val !== option));
                              }}
                            />
                          </HStack>
                        </Tag>
                      );
                    })} */}
            </Box>

            <When condition={!!selectedOption}>
              <Center>
                <Icon
                  as={FiX}
                  name="cancel"
                  cursor="pointer"
                  fontSize="md"
                  color={isDisabled ? 'blackAlpha.500' : 'inherit'}
                  _hover={{
                    color: isDisabled ? 'blackAlpha.500' : 'primary.500',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveIndex(0);
                    setInnerSearchText('');
                    onChange(null);
                  }}
                />
              </Center>
            </When>

            <Center>
              <Icon
                as={FiChevronDown}
                color={isDisabled ? 'blackAlpha.500' : 'inherit'}
                mr={3}
                onClick={() => {
                  onToggle();
                  InputRef.current?.focus();
                }}
              />
            </Center>
          </FakeInput>
        </PopoverAnchor>

        <PopoverContent w={InputContainerRef.current?.clientWidth}>
          <PopoverBody>
            <Box maxH="300px" overflowY="auto">
              <Stack spacing={2} py={1}>
                <When condition={isOptionsLoading}>
                  <Box px={2} py={1}>
                    <Loading size={'25px'} />
                  </Box>
                </When>
                <When condition={options.length === 0 && !isOptionsLoading}>
                  <Box px={2} py={1}>
                    <Text>{notFoundText}</Text>
                  </Box>
                </When>
                {/* todo multiple mode
                    {options
                      .filter((opt) => !value.includes(opt.value))
                      .map((opt, index) => (
                        <Box
                          key={index}
                          px={2}
                          py={1}
                          cursor="pointer"
                          rounded="md"
                          bg={active === index ? 'gray.200' : 'transparent'}
                          onMouseEnter={() => setActive(index)}
                          onClick={() => {
                            onChange([...value, opt.value]);
                            onClose();
                            resetActive();
                          }}
                        >
                          <Text>{opt.label}</Text>
                        </Box>
                      ))} */}
                {options.map((option, index) => (
                  // <Box
                  //   key={index}
                  //   px={2}
                  //   py={1}
                  //   cursor="pointer"
                  //   rounded="md"
                  //   bg={activeIndex === index ? 'gray.200' : 'transparent'}
                  //   onMouseEnter={() => setActiveIndex(index)}
                  //   onMouseDown={(event) => {
                  //     event.stopPropagation();
                  //     onChange(option);
                  //     setInnerSearchText(option.label);
                  //     resetActive();
                  //     onClose();
                  //   }}
                  // >
                  //   <Text>{option.label}</Text>
                  // </Box>
                  <AutocompleteOption
                    key={option.label + option.value + index}
                    active={activeIndex === index}
                    onMouseEnter={() => setActiveIndex(index)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      onChange(option);
                      setInnerSearchText(option.label);
                      resetActive();
                      onClose();
                    }}
                  >
                    {option.label}
                  </AutocompleteOption>
                ))}

                <When condition={!!onAddNewOption}>
                  <AutocompleteOption
                    active={activeIndex === -1}
                    onMouseEnter={() => setActiveIndex(-1)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      onAddNewOption && onAddNewOption();
                      resetActive();
                      onClose();
                    }}
                  >
                    {addNewOptionText}
                  </AutocompleteOption>
                </When>
              </Stack>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <When condition={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </When>
      <When condition={!!error}>
        <FormErrorMessage>{error}</FormErrorMessage>
      </When>
    </FormControl>
  );
};

export default BaseAutocompleteField;
