import { ForwardedRef, forwardRef } from 'react';
import { When } from 'react-if';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputProps } from '@chakra-ui/react';

interface Props extends InputProps {
  error?: string;
  label?: string;
  helperText?: string;
}

function InputField({ error, label, helperText, ...props }: Props, ref: ForwardedRef<HTMLInputElement>) {
  return (
    <FormControl isInvalid={!!error} isRequired={props.isRequired} isDisabled={props.isDisabled}>
      <When condition={!!label}>
        <FormLabel htmlFor={props.id}>{label}</FormLabel>
      </When>
      <Input {...props} ref={ref} />
      <When condition={!!helperText}>
        <FormHelperText color={props.isDisabled ? 'blackAlpha.500' : 'black'}>{helperText}</FormHelperText>
      </When>
      <When condition={!!error}>
        <FormErrorMessage>{error}</FormErrorMessage>
      </When>
    </FormControl>
  );
}

export default forwardRef<HTMLInputElement, Props>(InputField);
