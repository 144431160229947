import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { FiltersProvider } from '@common/contexts/Filters';
import { PaginationProvider } from '@common/contexts/Pagination';
import Detail from './Detail';
import List from './List';
import { FileFilters } from './types';

const paramFields: Array<keyof FileFilters> = ['q'];

const Files: FC & {
  List: FC;
  Detail: FC;
} = () => {
  return (
    <PaginationProvider>
      <FiltersProvider<FileFilters>
        paramStateOptions={{
          fields: paramFields,
        }}
      >
        <Outlet />
      </FiltersProvider>
    </PaginationProvider>
  );
};

Files.List = List;
Files.Detail = Detail;

export default Files;
