import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import BaseAutocomplete from '@components/BaseAutocomplete';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { UserSearch } from '.';

const AUTOCOMPLETE_ELEMENTS = 5;

interface Props extends AutocompleteProps {
  value?: UserSearch | null;
  onChange: (value: UserSearch | null) => void;

  isLoading?: boolean;
}

const ServiceAutocompleteField: FC<Props> = ({ value, onChange, ...props }) => {
  const [search, setSearch] = useState(value?.name || '');

  const { data, loading, error } = useQuery(SERVICE_QUERY, {
    variables: {
      filters: { name: search },
      pagination: {
        pageSize: AUTOCOMPLETE_ELEMENTS,
      },
    },
    skip: props.isDisabled,
  });

  if (error) throw error;

  return (
    <BaseAutocomplete<number>
      value={value ? { value: value.id, label: value.name || '' } : null}
      onChange={(_value) => onChange(_value ? { id: _value.value, name: _value.label } : null)}
      searchText={search}
      onSearchChange={setSearch}
      options={data?.serviceUserList.objects.map((i) => ({
        value: i.id,
        label: i.service?.name ? `${i.service.name} - ${i.name}` : i.name || '',
      }))}
      notFoundText={'Nessun utente trovato'}
      isOptionsLoading={loading}
      {...props}
    />
  );
};

const SERVICE_QUERY = graphql(`
  query UserListSearch($filters: ServiceUserFilters, $pagination: PaginationInput) {
    serviceUserList(filters: $filters, pagination: $pagination) {
      objects {
        id
        name
        service {
          id
          name
        }
      }
    }
  }
`);

export default ServiceAutocompleteField;
