import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LS_AUTH_KEY } from '@config/consts';

interface Props {
  loginPath: string;
}

const RequireAuth: FC<Props> = ({ loginPath }) => {
  const accessToken = localStorage.getItem(LS_AUTH_KEY)

  if (!accessToken) {
    console.log('Redirect to login')
    return <Navigate to={loginPath} />
  }

  if (accessToken) {
    console.log('Logged in with code:', accessToken);
    return <Outlet />;
  }
  

  return null;
};

export default RequireAuth;
