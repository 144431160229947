import { FC } from 'react';
import { Center, CenterProps, CircularProgress } from '@chakra-ui/react';

const Loading: FC<CenterProps & { size?: string | number }> = ({ size, ...props }) => {
  return (
    <Center h="full" w="full" {...props}>
      <CircularProgress size={size} isIndeterminate color="primary.500" />
    </Center>
  );
};

export default Loading;
