import { Theme, extendTheme, mergeThemeOverride, withDefaultColorScheme } from '@chakra-ui/react';

/* @ts-expect-error chakra lies */
export const defaultTheme: Theme & {
  colors: {
    primary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
  };
} = extendTheme(
  (theme: Theme) =>
    mergeThemeOverride(theme, {
      fonts: {
        heading: `'Montserrat', 'Open Sans', sans-serif`,
        body: `'Montserrat', 'Open Sans', sans-serif`,
      },

      colors: {
        primary: theme.colors.teal,
      },

      components: {
        Input: {
          defaultProps: {
            focusBorderColor: 'primary.500',
          },
        },
      },
    }),

  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Button', 'Input', 'Select', 'Checkbox', 'Radio', 'Switch', 'Slider', 'Tabs'],
  }),
);
