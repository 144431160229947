import { FC, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { ServiceUser } from '@gql/graphql';
import ServiceUserAutocompleteField from './Field';

export type ServiceUserSearch = Pick<ServiceUser, 'id' | 'name'>;

interface Props extends AutocompleteProps {
  serviceId: number;

  value?: ServiceUserSearch | number | null;
  onChange: (value: ServiceUserSearch | null) => void;
}

const Loader: FC<Props> = ({ value, serviceId, ...props }) => {
  const isValueID = useMemo(() => typeof value === 'number', [value]);
  const [internalValue, setInternalValue] = useState<ServiceUserSearch | null | undefined>(isValueID ? undefined : (value as ServiceUserSearch));

  const { loading, error } = useQuery(SERVICE_USER_QUERY, {
    variables: {
      // @ts-expect-error unreachable if undefined
      id: value,
    },
    skip: props.isDisabled || !isValueID || typeof internalValue !== 'undefined',
    onCompleted: (data) => setInternalValue(data?.serviceUser ? { id: data.serviceUser.id, name: data.serviceUser.name } : null),
  });

  const handleChange = (_value: ServiceUserSearch | null) => {
    setInternalValue(_value);
    props.onChange(_value);
  };

  if (error) throw error;

  return <ServiceUserAutocompleteField serviceId={serviceId} isLoading={loading} value={internalValue} {...props} onChange={handleChange} />;
};

const SERVICE_USER_QUERY = graphql(`
  query ServiceUserSearch($id: Int!) {
    serviceUser(id: $id) {
      id
      name
    }
  }
`);

export default Loader;
