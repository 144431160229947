import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { FiltersProvider } from '@common/contexts/Filters';
import { PaginationProvider } from '@common/contexts/Pagination';
import Detail from './Detail';
import List from './List';
import { ExpirationsFilters } from './types';

const paramFields: Array<keyof ExpirationsFilters> = ['title', 'notify', 'dateRange'];

const Expirations: FC & {
  List: FC;
  Detail: FC;
} = () => {
  return (
    <PaginationProvider>
      <FiltersProvider<ExpirationsFilters>
        paramStateOptions={{
          fields: paramFields,
        }}
      >
        <Outlet />
      </FiltersProvider>
    </PaginationProvider>
  );
};

Expirations.List = List;
Expirations.Detail = Detail;

export default Expirations;
