import React, { forwardRef, useCallback, useRef } from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import { DEBOUNCE_TIMEOUT } from '../../config/consts';

const InputDebounced = forwardRef<HTMLInputElement, InputProps>(({ onChange, ...props }, ref) => {
  const timeoutRef = useRef<number>();

  const handleChange = useCallback(
    (event) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        onChange && onChange(event);
      }, DEBOUNCE_TIMEOUT);
    },
    [onChange],
  );

  return <Input ref={ref} onChange={handleChange} {...props} />;
});

export default InputDebounced;
