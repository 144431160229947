import urlJoin from 'url-join';
import { ApolloClient, ApolloError, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { API_HOST, FETCH_TIMEOUT_MS, LS_AUTH_KEY } from './consts';

const authMiddleware = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'accessTokenCreate') {
    return forward(operation);
  }

  const accessToken = localStorage.getItem(LS_AUTH_KEY);

  if (!accessToken) {
    history.pushState({}, '', '/oauth/signin');
  }

  const impersonate = localStorage.getItem('impersonate')?.replace(/"/g, '');

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }),
      ...(impersonate && {
        'x-viridiana-impersonate': impersonate,
      }),
    },
  }));

  return forward(operation);
});

const timeoutedFetch = async (uri, options) => {
  if (!window.AbortController) {
    return fetch(uri, options);
  }

  const controller = new AbortController();
  const signal = controller.signal;

  const timer = setTimeout(() => {
    controller.abort('TIMEOUT');
  }, FETCH_TIMEOUT_MS);

  return await fetch(uri, { ...options, signal })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      console.error('Fetch error', error);
      if (error.name === 'AbortError') {
        return Promise.reject(
          new ApolloError({
            networkError: new Error('Response timeout'),
          }),
        );
      } else {
        return Promise.reject(error);
      }
    })
    .finally(() => {
      clearTimeout(timer);
    });
};

const logoutLink = onError(() => {
  // If client is online but server is unreachable, redirect to the maintenance page
  // if (navigator.onLine && !(await isServerUp())) {
  //   location.pathname = SERVER_UNDER_MAINTENANCE;
  // }
  // if (graphQLErrors?.[0].extensions.access_token === 'UNAUTHORIZED') {
  //   localStorage.removeItem(LS_TOKEN);
  //   if (location.pathname !== ROUTE.LOGIN) {
  //     location.pathname = ROUTE.LOGIN;
  //   }
  // }
});

const apiClient = new ApolloClient({
  uri: urlJoin(API_HOST, '/graphql/'),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          comments: relayStylePagination(),
        },
      },
    },
  }),
  link: logoutLink.concat(authMiddleware).concat(
    createHttpLink({
      uri: urlJoin(API_HOST, '/graphql/'),
      fetch: timeoutedFetch,
    }),
  ),
  // .concat(
  //   createUploadLink({
  //     uri: urlJoin(CORE_HOST, '/graphql/'),
  //     fetch: timeoutedFetch,
  //   }),
  // ),
});

export default apiClient;
