import { Text } from '@chakra-ui/react';
import { FCC } from '@types';

const ValueField: FCC = ({ children }) => {
  if (!children) {
    return (
      <Text color="gray.400" fontStyle="italic">
        Non definito
      </Text>
    );
  }
  return <>{children}</>;
};

export default ValueField;
