import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { Center, Link, Text, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import { Activity, ActivityInput } from '@gql/graphql';
import Form from './Form';

const Edit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(ACTIVITY_QUERY, {
    variables: {
      id: parseInt(id!, 10),
    },
  });

  const [updateActivity] = useMutation(ACTIVITY_UPDATE_MUTATION);

  if (loading) {
    return <Loading h="full" />;
  }

  if (error) {
    return <Center h="full">Si è verificato errore imprevisto</Center>;
  }

  if (!data?.activity) {
    return (
      <Center h="full">
        <VStack spacing={4}>
          <Text>Questa attività non esiste o è stata rimossa</Text>
          <Link color="primary.700" href={ROUTE.ACTIVITIES}>
            Torna alla lista
          </Link>
        </VStack>
      </Center>
    );
  }

  const handleSubmit = async (values: ActivityInput) => {
    const res = await updateActivity({
      variables: {
        activityUpdateId: parseInt(id!, 10),
        data: values,
      },
    });

    if (!res?.data) {
      throw new Error('Si è verificato un errore imprevisto');
    }

    if (res.data.activityUpdate.errors.length > 0) {
      return res.data.activityUpdate.errors;
    } else {
      toast.success('Attività modificata con successo');
      navigate(ROUTE.ACTIVITIES_DETAIL.replace(':id', id!));
    }
  };

  return <Form data={data.activity as Partial<Activity>} onSubmit={handleSubmit} title="Modifica attività" />;
};

const ACTIVITY_QUERY = graphql(`
  query ActivityEditEmployee($id: Int!) {
    activity(id: $id) {
      date
      endTime
      startTime
      user {
        id
        name
      }
      service {
        id
        name
        clientId
        sectorCode
        users {
          name
        }
      }
    }
  }
`);

const ACTIVITY_UPDATE_MUTATION = graphql(`
  mutation ActivityUpdateEmployee($activityUpdateId: Int!, $data: ActivityInput!) {
    activityUpdate(id: $activityUpdateId, data: $data) {
      errors
      object {
        id
      }
    }
  }
`);

export default Edit;
