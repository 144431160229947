import { FC, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { ServiceUser } from '@gql/graphql';
import ServiceAutocompleteField from './Field';

export type UserSearch = Pick<ServiceUser, 'id' | 'name' | 'service'>;

interface Props extends AutocompleteProps {
  value?: UserSearch | number | null;
  onChange: (value: UserSearch | null) => void;
}

const Loader: FC<Props> = ({ value, ...props }) => {
  const isValueID = useMemo(() => typeof value === 'number', [value]);
  const [internalValue, setInternalValue] = useState<UserSearch | null | undefined>(isValueID ? undefined : (value as UserSearch));

  const { loading, error } = useQuery(USER_QUERY, {
    variables: {
      // @ts-expect-error unreachable if undefined
      id: value,
    },
    fetchPolicy: 'network-only',
    skip: props.isDisabled || !isValueID || typeof internalValue !== 'undefined',
    onCompleted: (data) => setInternalValue(data?.serviceUser ? { id: data.serviceUser.id, name: data.serviceUser.name! } : null),
  });

  const handleChange = (_value: UserSearch | null) => {
    setInternalValue(_value);
    props.onChange(_value);
  };

  if (error) throw error;

  return <ServiceAutocompleteField isLoading={loading} value={null} {...props} onChange={handleChange} />;
};

const USER_QUERY = graphql(`
  query UserSearchDetail($id: Int!) {
    serviceUser(id: $id) {
      id
      name
      service {
        id
        name
      }
    }
  }
`);

export default Loader;
