import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import BaseAutocomplete from '@components/BaseAutocomplete';
import { AutocompleteProps } from '@components/BaseAutocomplete/types';
import { graphql } from '@gql/gql';
import { ServiceSearch } from '.';

const AUTOCOMPLETE_ELEMENTS = 5;

interface Props extends AutocompleteProps {
  value?: ServiceSearch | null;
  onChange: (value: ServiceSearch | null) => void;

  isLoading?: boolean;
}

const ServiceAutocompleteField: FC<Props> = ({ value, onChange, ...props }) => {
  const [search, setSearch] = useState(value?.name || '');

  const { data, loading, error } = useQuery(SERVICE_QUERY, {
    variables: {
      filters: { q: search },
      pagination: {
        pageSize: AUTOCOMPLETE_ELEMENTS,
      },
    },
    skip: props.isDisabled,
  });

  if (error) throw error;

  return (
    <BaseAutocomplete<number>
      value={value ? { value: value.id, label: value.name || '' } : null}
      onChange={(_value) => onChange(_value ? { id: _value.value, name: _value.label } : null)}
      searchText={search}
      onSearchChange={setSearch}
      options={data?.serviceList.objects.map((service) => ({
        value: service.id,
        label: service.client?.name ? `${service.client.name} - ${service.name}` : service.name || '',
      }))}
      notFoundText={'Nessun cantiere trovato'}
      isOptionsLoading={loading}
      {...props}
    />
  );
};

const SERVICE_QUERY = graphql(`
  query ServiceListSearch($filters: ServiceFilters, $pagination: PaginationInput) {
    serviceList(filters: $filters, pagination: $pagination) {
      objects {
        id
        name
        client {
          name
        }
      }
    }
  }
`);

export default ServiceAutocompleteField;
