const switchEnv = (config: { [host: string]: string }) => {
  const host = window.location.host;
  return config[host] || config['default'] || '';
};

export const FETCH_TIMEOUT_MS = 15000;
export const DEFAULT_PAGE_SIZE = 10;
export const SHORT_PAGE_SIZE = 5;
export const DEBOUNCE_TIMEOUT = 800;
export const LS_AUTH_KEY = 'viridiana-token';

export const HOST = `${window.location.protocol}//${window.location.host}`;

export const API_HOST = import.meta.env.PUBLIC_API_HOST || 'http://localhost:8000';
export const ADMIN_HOST = import.meta.env.PUBLIC_ADMIN_HOST || 'http://localhost:5000';
export const EMPLOYEE_HOST = import.meta.env.PUBLIC_EMPLOYEE_HOST || 'http://localhost:5100';

export const OAUTH_AUTHORITY_URL: string = switchEnv({
  default: 'https://accounts.google.com/',
});

export const OAUTH_CLIENT_ID: string = switchEnv({
  default: '668622874906-rv1rr5q0n1kbo6klgllo9m0pah0cfu4i.apps.googleusercontent.com',
  // default: '668622874906-f31k46tav3d4hdrqfrpic45i19mt1blb.apps.googleusercontent.com',
});

export const OAUTH_CLIENT_SECRET: string = switchEnv({
  default: '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',
});
