import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '@common/components/Layout';
import NotFound from '@common/components/NotFound';
import RequireAuth from '@common/components/RequireAuth';
import { ROUTE, menu } from '@config/routing';
import Activities from '@routes/Activities';
import Expirations from '@routes/Expirations';
import Files from '@routes/Files';
import Impersonate from '@routes/Impersonate';
import OAuthSignin from '@routes/OAuth/Signin';
import Stamp from '@routes/Stamp';

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={ROUTE.OAUTH_CALLBACK} element={<OAuthCallback />} /> */}
        <Route path={ROUTE.OAUTH_SIGNIN} element={<OAuthSignin />} />

        <Route path={ROUTE.IMPERSONATE} element={<Impersonate />} />

        {/* PRIVATE ROUTES */}
        <Route element={<RequireAuth loginPath={ROUTE.OAUTH_SIGNIN} />}>
          {/* ROUTES WITH DEFAULT ADMIN LAYOUT */}
          <Route element={<Layout menu={menu} title="Portale Dipendente" />}>
            <Route path={ROUTE.ACTIVITIES} element={<Activities />}>
              <Route index element={<Activities.List />} />
              <Route path={ROUTE.ACTIVITIES_DETAIL} element={<Activities.Detail />} />
              <Route path={ROUTE.ACTIVITIES_CREATE} element={<Activities.Create />} />
              <Route path={ROUTE.ACTIVITIES_EDIT} element={<Activities.Edit />} />
            </Route>

            <Route path={ROUTE.STAMP} element={<Stamp />} />

            <Route path={ROUTE.EXPIRATIONS} element={<Expirations />}>
              <Route index element={<Expirations.List />} />
              <Route path={ROUTE.EXPIRATIONS_DETAIL} element={<Expirations.Detail />} />
            </Route>

            <Route path={ROUTE.FILES} element={<Files />}>
              <Route index element={<Files.List />} />
              <Route path={ROUTE.FILES_DETAIL} element={<Files.Detail />} />
            </Route>

            <Route index element={<Activities.Create />} />
          </Route>
        </Route>

        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
