import { format } from 'date-fns';

export const formatHumanReadableNumber = (number: number) => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export default function formatTimeStampDate(timestamp) {
  return format(new Date(timestamp * 1000) as any, 'dd MMMM yyyy');
}

export function capitalize(str: string): string {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

export function stripTags(string: string) {
  if (string === null || string === '' || string == undefined) return '';
  else string = string.toString();

  // Regular expression to identify HTML tags in
  return string.replace(/(<([^>]+)>)/gi, '');
}

export function slugify(string: string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  if (!string) {
    return '';
  }

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function safeDateFormat(date?: string | Date | null, fmt = 'dd MMMM yyyy') {
  if (!date) return '';
  try {
    return format(new Date(date), fmt);
  } catch (e) {
    console.warn('Error formatting date', e);
    return date.toString();
  }
}

export const cleanEmail = (email?: string) => {
  if (!email) return '';
  return email.split('@')[0];
};
