import { FC } from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
  active: boolean;
}

const AutocompleteOption: FC<Props> = ({ children, active, ...props }) => {
  return (
    <Box px={2} py={1} cursor="pointer" rounded="md" bg={active ? 'gray.200' : 'transparent'} {...props}>
      <Text>{children}</Text>
    </Box>
  );
};

export default AutocompleteOption;
