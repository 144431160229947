import { createContext, useCallback, useContext } from 'react';
import useParamState, { UseParamStateOptions } from '../hooks/useParamState';
import { usePagination } from './Pagination';

export interface FiltersContextState<T> {
  filters: T;
  push: (key: keyof T, value: T[keyof T] | null) => void;
  setFilters: (filters: T) => void;
  remove: (key: keyof T) => void;
}

export const FiltersContext = createContext<FiltersContextState<any>>({
  filters: {},
  push: () => undefined,
  setFilters: () => undefined,
  remove: () => undefined,
});

export interface FilterProviderProps<T> {
  initFilters?: T | null;
  paramStateOptions?: UseParamStateOptions<T>;
}

export const useFilters = <T,>(): FiltersContextState<T> => {
  const context = useContext<FiltersContextState<T>>(FiltersContext);

  if (context === undefined) {
    throw new Error('You must wrap useFilters with an FiltersProvider');
  }

  return context;
};

export const FiltersProvider = <T extends Record<string | number | symbol, any>>({
  initFilters,
  paramStateOptions,
  children,
}: FilterProviderProps<T> & { children: JSX.Element }): JSX.Element => {
  const [filters, setFilters] = useParamState<T>(initFilters || ({} as T), paramStateOptions) as [T, (state: T) => void];

  const { setPage } = usePagination();

  const push = useCallback(
    (key: keyof T, value: T[keyof T]) => {
      setFilters({ ...filters, [key]: value });

      if (setPage) setPage(1);
    },
    [filters, setFilters],
  );

  const remove = useCallback(
    (key: keyof T) => {
      const newFilters = { ...filters };
      // @ts-expect-error - key will be removed in useParamState
      if (key in newFilters) newFilters[key] = undefined;
      setFilters(newFilters);

      if (setPage) setPage(1);
    },
    [filters, setFilters],
  );

  return <FiltersContext.Provider value={{ push, filters, setFilters, remove }}>{children}</FiltersContext.Provider>;
};

/* HOC that provide FiltersContext to component */
export const withFilters = <T extends Record<string, any>>({ children, initFilters }: FilterProviderProps<T> & { children: JSX.Element }) => {
  return <FiltersProvider<T> initFilters={initFilters}>{children}</FiltersProvider>;
};
