import { forwardRef } from 'react';
import { ForwardRefRenderFunction } from 'react';
import { HStack, StackProps } from '@chakra-ui/react';

interface Props extends StackProps {
  error?: string | null;
  isDisabled?: boolean;
}

const FakeInput: ForwardRefRenderFunction<HTMLDivElement, Props> = ({ children, ...props }, ref) => (
  <HStack
    ref={ref}
    w="full"
    h="37.5px"
    borderRadius="md"
    borderWidth={props.error ? 2 : 1}
    borderColor={props.isDisabled ? 'blackAlpha.200' : props.error ? 'red.400' : 'gray.200'}
    cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
    boxShadow={'none'}
    transition="all 0.2s"
    _hover={{
      borderColor: props.isDisabled ? 'gray.200' : 'gray.300',
    }}
    {...props}
  >
    {children}
  </HStack>
);

export default forwardRef(FakeInput);
