import { isObject } from '@types';

const cleanObject = (obj: Record<string, any>): Record<string, any> => {
  if (obj instanceof Date) {
    return obj;
  }

  let pairs = Object.entries(obj);

  // Clean nested objects and arrays
  pairs = pairs.map(([key, value]) => {
    return [key, cleanFalsy(value)];
  });

  // Remove nullish properties
  pairs = pairs.filter(([_key, value]) => typeof value === 'number' || !!value);

  return Object.fromEntries(pairs);
};

const cleanArray = (array: Array<any>): Array<any> => {
  let arrayClone = [...array];

  // If it's a string array, sort elements alphabetically
  if (arrayClone.every((element) => typeof element === 'string')) {
    arrayClone.sort();
  }

  // Clean nested objects and arrays
  arrayClone = arrayClone.map((element) => cleanFalsy(element));

  // Remove nullish elements
  arrayClone = arrayClone.filter((element) => typeof element === 'number' || !!element);

  return arrayClone;
};

/**
 * Clean objects and their nested objects from empty arrays, empty strings,
 * properties with undefined, false and null values. It leaves numbers (including NaN (for Date instance compatibility)) and empty objects.
 * Sort string arrays alphabetically
 * @param object An object to clean
 * @return The cleaned object
 */
const cleanFalsy = (object: any) => {
  if (isObject(object)) {
    return cleanObject(object);
  } else if (Array.isArray(object)) {
    if (object.length) {
      return cleanArray(object);
    } else {
      return undefined;
    }
  } else return object;
};

export default cleanFalsy;
