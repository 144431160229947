import { FC, useRef } from 'react';
import { Case, Switch } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Center, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import Pagination from '@common/components/Pagination';
import { DEFAULT_PAGE_SIZE } from '@common/config/consts';
import { useFilters } from '@common/contexts/Filters';
import { usePagination } from '@common/contexts/Pagination';
import { safeDateFormat } from '@common/utils/formatters';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import { ExpirationSort } from '@gql/graphql';
import { ExpirationsFilters } from '../types';
import Filters from './Filters';

const List: FC = () => {
  const { page, setPage } = usePagination();
  const bodyRef = useRef<HTMLDivElement>(null);
  const { filters } = useFilters<ExpirationsFilters>();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(EXPIRATION_QUERY, {
    variables: {
      pagination: {
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      filters: {
        dateFrom: safeDateFormat(filters.dateRange?.[0], 'yyyy-MM-dd') || undefined,
        dateTo: safeDateFormat(filters.dateRange?.[1], 'yyyy-MM-dd') || undefined,
        title: filters.title,
        notify: filters.notify,
      },
      sort: ExpirationSort.DateDesc,
    },
  });

  return (
    <VStack spacing={6} p={6} w="full" h="full">
      <Filters />

      <VStack spacing={4} justify="space-between" h="full" w="full" flexGrow={1} ref={bodyRef}>
        <Switch>
          <Case condition={!!error}>
            <Center h="full">Si è verificato errore imprevisto</Center>;
          </Case>

          <Case condition={!!loading}>
            <Loading />
          </Case>

          <Case condition={!data?.expirationList.objects.length}>
            <Center h="full">Nessun risultato trovato</Center>
          </Case>

          <Case condition={!!data}>
            <TableContainer w="full">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Scadenza</Th>
                    <Th>Titolo</Th>
                    <Th w="50px">Notifica</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data?.expirationList.objects.map((expiration) => (
                    <Tr
                      key={expiration.id}
                      cursor="pointer"
                      _hover={{ bgColor: 'gray.100' }}
                      onClick={() => navigate(ROUTE.EXPIRATIONS_DETAIL.replace(':id', expiration.id.toString() || ''))}
                    >
                      <Td>{safeDateFormat(expiration.date, 'd MMM yyyy')}</Td>
                      <Td>{expiration.title}</Td>
                      <Td>{expiration.notify ? 'Si' : 'No'}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Case>
        </Switch>

        <Pagination page={page} numPages={data?.expirationList.numPages} total={data?.expirationList.count} setPage={setPage} />
      </VStack>
    </VStack>
  );
};

const EXPIRATION_QUERY = graphql(`
  query expirationListemployee($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {
    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {
      objects {
        id
        date
        notify
        title
      }
      count
      numPages
    }
  }
`);

export default List;
