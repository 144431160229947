import { FC, useState } from 'react';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useQuery } from '@apollo/client';
import { Button, HStack, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import InputField from '@common/components/InputField';
import Loading from '@common/components/Loading';
import ServiceAutocomplete from '@common/components/ServiceAutocomplete';
import ServiceUserAutocomplete from '@common/components/ServiceUserAutocomplete';
import ValueField from '@common/components/ValueField';
import { graphql } from '@gql/gql';
import { Activity, ActivityInput } from '@gql/graphql';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  title: string;
  data?: Partial<Activity> | null;
  onSubmit: (data: ActivityInput) => Promise<Array<string> | undefined>;
}

const Form: FC<Props> = ({ data, title, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { data: accountData } = useQuery(ACCOUNT_QUERY);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<ActivityInput>({
    defaultValues: {
      date: data?.date || format(new Date(), 'yyyy-MM-dd'),
      startTime: data?.startTime,
      endTime: data?.endTime,
      serviceId: data?.service?.id,
      serviceUserId: data?.user?.id,
    },
    resolver: yupResolver(schema),
  });

  const submit = async (data: ActivityInput) => {
    setIsSubmitting(true);
    try {
      const res = await onSubmit(data);

      if (!!res && res.length > 0) {
        toast.error(res.join(' - '));
      }
    } catch (e) {
      console.error(e);
      toast.error('Si è verificato un errore imprevisto');
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack direction="column" spacing={8} p={6}>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} justify="space-between">
          <Text fontWeight="medium" fontSize="xl">
            {title}
          </Text>

          <HStack spacing={2}>
            <Button
              colorScheme="primary"
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FiEdit} />}
              isLoading={isSubmitting}
              type="submit"
              isDisabled={!accountData?.account.isEnabledManualActivity}
            >
              Salva
            </Button>
            <Button colorScheme="blackAlpha" variant="outline" size="sm" onClick={() => navigate(-1)}>
              Annulla
            </Button>
          </HStack>
        </Stack>

        <SimpleGrid w="full" gridTemplateColumns={{ base: '1fr', sm: '1fr 2fr' }} spacing={{ base: 6, sm: 4 }} p={2}>
          <Text fontWeight="medium" marginY="auto">
            Cantiere
          </Text>
          <ValueField>
            <Controller
              control={control}
              name="serviceId"
              disabled={isSubmitting}
              render={({ field }) => (
                <ServiceAutocomplete
                  value={field.value}
                  onChange={(val) => {
                    field.onChange(val?.id);
                    setValue('serviceUserId', null);
                  }}
                  error={errors.serviceId?.message?.toString()}
                  helperText="Inizia a digitare in questo campo per aggiornare automaticamente la lista"
                />
              )}
            />
          </ValueField>

          <Text fontWeight="medium" marginY="auto">
            Utente
          </Text>
          <ValueField>
            <Controller
              control={control}
              disabled={isSubmitting}
              name="serviceUserId"
              render={({ field, fieldState }) => (
                <ServiceUserAutocomplete
                  isDisabled={!watch('serviceId')}
                  serviceId={watch('serviceId')}
                  value={field.value}
                  onChange={(val) => field.onChange(val?.id)}
                  error={fieldState.error?.message?.toString()}
                  helperText="Inizia a digitare in questo campo per aggiornare automaticamente la lista"
                />
              )}
            />
          </ValueField>

          <Text fontWeight="medium" marginY="auto">
            Data
          </Text>
          <ValueField>
            <InputField type="date" {...register('date')} error={errors.date?.message?.toString()} />
          </ValueField>

          <Text fontWeight="medium" marginY="auto">
            Orario di inizio
          </Text>
          <ValueField>
            <InputField type="time" {...register('startTime')} error={errors.startTime?.message?.toString()} />
          </ValueField>

          <Text fontWeight="medium" marginY="auto">
            Orario di fine
          </Text>
          <ValueField>
            <InputField type="time" {...register('endTime')} error={errors.endTime?.message?.toString()} />
          </ValueField>
        </SimpleGrid>
      </Stack>
    </form>
  );
};

const schema = yup.object({
  date: yup.string().required("L'attività deve avere una data"),
  startTime: yup.string().required("L'attività deve avere un orario di inizio"),
  endTime: yup.string().required("L'attività deve avere un orario di fine"),
  serviceId: yup.number().required("L'attività deve essere associata ad un cantiere"),
  serviceUserId: yup.number().nullable(),
  staffId: yup.number().nullable(),
});

const ACCOUNT_QUERY = graphql(`
  query SubAccountForm {
    account {
      isEnabledManualActivity
    }
  }
`);

export default Form;
