import { FC } from 'react';
import * as Sentry from '@sentry/react';

const ErrorFallback: FC<{ error: any }> = ({ error }) => {
  Sentry.captureException(error);

  return (
    <div
      style={{
        display: 'flex',
        fontFamily: 'Montserrat, sans-serif',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div style={{ fontSize: '30px', paddingBottom: 20, textAlign: 'center' }}>Ops! Something went wrong.</div>

      {import.meta.env.MODE === 'development' && (
        <>
          <div
            style={{
              fontSize: '20px',
              paddingBottom: 10,
              color: 'red',
              textAlign: 'center',
            }}
          >
            {error.message}
          </div>

          {/* Print stack or json of error */}
          <div style={{ textAlign: 'left', overflow: 'auto' }}>
            {error.stack
              ? error.stack.split('\n').map((line, i) => (
                  <code key={i} style={{ lineHeight: 0.6 }}>
                    <pre>
                      {line}
                      <br />
                    </pre>
                  </code>
                ))
              : JSON.stringify(error, null, 2)
                  .split('\n')
                  .map((line, i) => (
                    <code key={i} style={{ lineHeight: 0.6 }}>
                      <pre>
                        {line}
                        <br />
                      </pre>
                    </code>
                  ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ErrorFallback;
