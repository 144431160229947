import { FC, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { When } from 'react-if';
import { Icon, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react';
import InputDebounced from '@components/InputDebounced';

interface Props extends Omit<InputProps, 'onChange'> {
  value: string;
  onChange: (value: string | null) => void;
  name: string;
  placeholder?: string;
  icon: JSX.Element;
}

const FilterInputField: FC<Props> = ({ value, name, onChange, placeholder, icon, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
      <InputDebounced ref={ref} pl={9} defaultValue={value} placeholder={placeholder} onChange={(event) => onChange(event.target.value)} {...props} />
      <When condition={!!value}>
        <InputRightElement pointerEvents="visible">
          <Icon
            color="gray.500"
            as={FiX}
            cursor="pointer"
            onClick={(event) => {
              event.stopPropagation();
              onChange(null);
              if (ref?.current) {
                ref.current.value = '';
              }
            }}
          />
        </InputRightElement>
      </When>
    </InputGroup>
  );
};

export default FilterInputField;
