import { GoogleDriveFileVisibility } from '../../../gql-types/types/graphql';

export const tFileVisibility = (visibility: GoogleDriveFileVisibility) => {
  switch (visibility) {
    case GoogleDriveFileVisibility.Private:
      return 'Privato';
    case GoogleDriveFileVisibility.Public:
      return 'Pubblico';
    case GoogleDriveFileVisibility.Shared:
      return 'Condiviso';
  }
};
