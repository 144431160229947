import React, { FC } from 'react';
import { FiSend, FiTrash } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  ButtonProps,
  Card,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import UserAutocomplete, { UserSearch } from '@common/components/UserAutocomplete';
import { graphql } from '@gql/gql';
import { AspaReportType } from '@gql/graphql';

type Props = ButtonProps;

const AspaButton: FC<Props> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reportType, setReportType] = React.useState<AspaReportType>(AspaReportType.Adp);
  const [month, setMonth] = React.useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [users, setUsers] = React.useState<Array<UserSearch>>([]);

  const { data } = useQuery(ACCOUNT_QUERY);
  const [reportSendAspa, { loading: reportSendLoading }] = useMutation(REPORT_MUTATION);

  const handleDownload = React.useCallback(() => {
    if (users && month && year) {
      reportSendAspa({
        variables: {
          staffEmail: '',
          month: month,
          year: year,
          selectedUsers: users.map((s) => s.id),
          type: reportType,
        },
      })
        .then(() => {
          toast.success('Report inviato');
        })
        .catch((error) => {
          toast.error('Si è verificato un errore imprevisto');
          console.error(error);
        });
    }
  }, [users, month, year, reportType]);

  return (
    <>
      <Button onClick={onOpen} {...props} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scarica Report ASPA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <FormControl as="fieldset">
                <FormLabel as="legend">Tipo Report</FormLabel>
                <RadioGroup value={reportType} onChange={(nextValue: AspaReportType) => setReportType(nextValue)}>
                  <Stack pl={3}>
                    <Radio colorScheme="primary" value={AspaReportType.Adp}>
                      ADP
                    </Radio>
                    <Radio colorScheme="primary" value={AspaReportType.Adm}>
                      ADM
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Mese</FormLabel>
                <InputGroup flexDirection="row" gap={4}>
                  <FormControl isRequired>
                    <Select
                      placeholder="Seleziona un mese"
                      value={month}
                      onChange={(e) => {
                        setMonth(parseInt(e.target.value));
                      }}
                    >
                      <option value="1">Gennaio</option>
                      <option value="2">Febbraio</option>
                      <option value="3">Marzo</option>
                      <option value="4">Aprile</option>
                      <option value="5">Maggio</option>
                      <option value="6">Giugno</option>
                      <option value="7">Luglio</option>
                      <option value="8">Agosto</option>
                      <option value="9">Settembre</option>
                      <option value="10">Ottobre</option>
                      <option value="11">Novembre</option>
                      <option value="12">Dicembre</option>
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <Select
                      placeholder="Seleziona un anno"
                      value={year}
                      onChange={(e) => {
                        setYear(parseInt(e.target.value));
                      }}
                    >
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </Select>
                  </FormControl>
                </InputGroup>
              </FormControl>

              <FormControl as="fieldset">
                <UserAutocomplete
                  mt={5}
                  onChange={(value) => {
                    if (value && !users.find((s) => s.id === value.id)) {
                      setUsers([...users, value]);
                    }
                  }}
                  value={null}
                  label="Utenti"
                  helperText="Inizia a digitare in questo campo per aggiornare automaticamente la lista"
                />
                <VStack mt={2} align="start" mx={4}>
                  {users.map((user) => (
                    <Card key={user.id} p={2} borderRadius="md" border="1px solid" borderColor="gray.200" boxShadow="none" w="100%">
                      <HStack>
                        <Text flexGrow={1}>{user.name}</Text>
                        <Icon
                          as={FiTrash}
                          color="gray.500"
                          onClick={() => {
                            setUsers(users.filter((s) => s.id !== user.id));
                          }}
                        />
                      </HStack>
                    </Card>
                  ))}
                </VStack>
              </FormControl>

              <Box textAlign="center">
                <Text>Il report verrà spedito in allegato alla tua e-mail:</Text>
                <Text fontWeight="semibold">{data?.account.email}</Text>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button leftIcon={<Icon as={FiSend} />} w="full" onClick={handleDownload} isLoading={reportSendLoading} zIndex={9}>
              Invia Report
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ACCOUNT_QUERY = graphql(`
  query AspaAccount {
    account {
      email
    }
  }
`);

const REPORT_MUTATION = graphql(`
  mutation ReportSendAspa($staffEmail: String!, $selectedUsers: [Int!]!, $month: Int!, $year: Int!, $type: AspaReportType!) {
    reportSendAspa(staffEmail: $staffEmail, selectedUsers: $selectedUsers, month: $month, year: $year, type: $type)
  }
`);

export default AspaButton;
