import { FC, useMemo } from 'react';
import { When } from 'react-if';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Center, Text, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import { safeDateFormat } from '@common/utils/formatters';
import { graphql } from '@gql/gql';

const Stamp: FC = () => {
  const [_params] = useSearchParams();
  const params = useMemo(() => Object.fromEntries(_params.entries()), [_params]);

  if (!params.serviceId) {
    throw new Error('Codice QR non valido');
  }

  const [activityStamp, { loading, error, data }] = useMutation(ACTIVITY_STAMP_QUERY, {
    variables: {
      data: {
        serviceId: parseInt(params.serviceId),
        serviceUserId: parseInt(params.serviceUserId) || null,
      },
    },
  });

  if (loading) return <Loading />;
  if (error) throw new Error('Errore imprevisto nella comunicazione con il server');

  if (data?.activityStamp.errors.length) {
    return (
      <Center h="100vh">
        <VStack key={error}>
          {data.activityStamp.errors.map((error, index) => (
            <Text key={index}>Errore: {error}</Text>
          ))}
        </VStack>
      </Center>
    );
  }

  return (
    <VStack mt={8}>
      {data?.activityStamp.object ? (
        <>
          <Text fontSize="lg">Timbratura effettuata con successo!</Text>

          <When condition={data.activityStamp.object.isStart}>
            <Text fontSize="lg">Inizio attività alle {safeDateFormat(data.activityStamp.object.activity.startTime, 'MM:HH')}</Text>
          </When>

          <When condition={!data.activityStamp.object.isStart}>
            <Text fontSize="lg">Fine attività alle {safeDateFormat(data.activityStamp.object.activity.endTime, 'MM:HH')}</Text>
          </When>
        </>
      ) : (
        <Button
          colorScheme="primary"
          size="lg"
          isLoading={loading}
          type="button"
          isDisabled={loading}
          onClick={() => {
            activityStamp();
          }}
        >
          Timbra
        </Button>
      )}
    </VStack>
  );
};

const ACTIVITY_STAMP_QUERY = graphql(`
  mutation ActivityStamp($data: ActivityStampInput!) {
    activityStamp(data: $data) {
      object {
        isStart
        activity {
          id
          date
          startTime
          endTime
        }
      }
      errors
    }
  }
`);

export default Stamp;
