import { FC } from 'react';
import { FiCalendar, FiFileText, FiPlusCircle, FiSearch } from 'react-icons/fi';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Button, Flex, FormControl, Icon, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import FilterInputField from '@common/components/FilterInputField';
import { useFilters } from '@common/contexts/Filters';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import { ActivitiesFilters } from '../types';
import AspaButton from './AspaButton';
import ReportAdmButton from './ReportAdmButton';
import ReportButton from './ReportButton';

const Filters: FC = () => {
  const navigate = useNavigate();
  const { filters, push } = useFilters<ActivitiesFilters>();
  const { data } = useQuery(ACCOUNT_QUERY);

  return (
    <Box w="full">
      <Flex
        gap={4}
        flexDirection={{
          base: 'column',
          xl: 'row',
        }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiSearch} />
          </InputLeftElement>
          <FilterInputField
            name="service"
            value={filters.service}
            onChange={(v) => push('service', v)}
            placeholder="Cerca per cantiere"
            icon={<Icon color="gray.500" as={FiSearch} />}
          />
        </InputGroup>
        {/*
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiSearch} />
          </InputLeftElement>
          <FilterInputField
            name="serviceClient"
            value={filters.serviceClient}
            onChange={(v) => push('serviceClient', v)}
            placeholder="Cerca per committente"
            icon={<Icon color="gray.500" as={FiSearch} />}
          />
        </InputGroup> */}
      </Flex>

      <Flex
        gap={4}
        flexDirection={{
          base: 'column',
          xl: 'row',
        }}
        mt={4}
      >
        {/* <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiSearch} />
          </InputLeftElement>
          <FilterInputField
            name="sector"
            value={filters.sector}
            onChange={(v) => push('sector', v)}
            placeholder="Cerca per settore"
            icon={<Icon color="gray.500" as={FiSearch} />}
          />
        </InputGroup> */}

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiSearch} />
          </InputLeftElement>
          <FilterInputField
            name="user"
            value={filters.user}
            onChange={(v) => push('user', v)}
            placeholder="Cerca per utente"
            icon={<Icon color="gray.500" as={FiSearch} />}
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiCalendar} />
          </InputLeftElement>
          <FormControl>
            <Select
              value={filters.month || new Date().getMonth() + 1}
              onChange={(e) => {
                push('month', parseInt(e.target.value));
              }}
              pl={10}
            >
              <option value="1">Gennaio</option>
              <option value="2">Febbraio</option>
              <option value="3">Marzo</option>
              <option value="4">Aprile</option>
              <option value="5">Maggio</option>
              <option value="6">Giugno</option>
              <option value="7">Luglio</option>
              <option value="8">Agosto</option>
              <option value="9">Settembre</option>
              <option value="10">Ottobre</option>
              <option value="11">Novembre</option>
              <option value="12">Dicembre</option>
            </Select>
          </FormControl>
        </InputGroup>

        <Flex
          gap={2}
          flexDirection={{
            base: 'column',
            xl: 'row',
          }}
        >
          <Button
            minW="150px"
            variant="outline"
            leftIcon={<FiPlusCircle />}
            onClick={() => navigate(ROUTE.ACTIVITIES_CREATE)}
            isDisabled={!data?.account.isEnabledManualActivity}
          >
            Aggiungi
          </Button>
          <ReportButton variant="solid" leftIcon={<FiFileText />}>
            Scheda Mensile
          </ReportButton>

          <When condition={!!data?.account?.isAspa}>
            <ReportAdmButton variant="solid" leftIcon={<FiFileText />}>
              Scheda ASPA ADM
            </ReportAdmButton>
          </When>

          <When condition={!!data?.account?.isAspa}>
            <AspaButton variant="solid" leftIcon={<FiFileText />}>
              Report ASPA
            </AspaButton>
          </When>
        </Flex>
      </Flex>
    </Box>
  );
};

const ACCOUNT_QUERY = graphql(`
  query SubAccount {
    account {
      isAspa
      isEnabledManualActivity
    }
  }
`);

export default Filters;
