import { FC } from 'react';
import { setDefaultOptions } from 'date-fns';
import { it as date_it } from 'date-fns/locale';
import * as ReactDOMClient from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import ErrorFallback from '@common/components/ErrorFallback';
import { OAUTH_CLIENT_ID } from '@common/config/consts';
import apiClient from '@common/config/network';
import { defaultTheme } from '@common/config/theming';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import Router from './Router';

Sentry.init({
  dsn: 'https://99434f58640fa716eb6fdfc47e9c64ba@o4506631834435584.ingest.sentry.io/4506631875067904',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/dipendenti\.viridiana\.info/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: !import.meta.env.DEV,
});

const container = document.getElementById('root');
if (!container) throw new Error('Root div not found');
const root = ReactDOMClient.createRoot(container);

setDefaultOptions({ locale: date_it });

const App: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={location.reload}>
      <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
        <ApolloProvider client={apiClient}>
          <ChakraProvider theme={defaultTheme} portalZIndex={40}>
            <Router />
            <ToastContainer autoClose={4000} position="bottom-center" hideProgressBar />
          </ChakraProvider>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};

root.render(<App />);
