import { FC } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { When } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Center, HStack, Icon, Link, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import ValueField from '@common/components/ValueField';
import { tFileVisibility } from '@common/utils/enumTranslate';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';

const Detail: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(FILE_QUERY, {
    variables: {
      googleDriveFileId: id!,
    },
  });

  if (loading) {
    return <Loading h="full" />;
  }

  if (error) {
    return <Center h="full">Si è verificato errore imprevisto</Center>;
  }

  if (!data?.googleDriveFile) {
    return (
      <Center h="full">
        <VStack spacing={4}>
          <Text>Questo file non esiste o è stata rimosso</Text>
          <Link color="primary.700" href={ROUTE.FILES}>
            Torna alla lista
          </Link>
        </VStack>
      </Center>
    );
  }

  const { googleDriveFile } = data;

  return (
    <Stack direction="column" spacing={8} p={6}>
      <HStack spacing={4} justify="space-between">
        <Text fontWeight="medium" fontSize="xl">
          Dettaglio documento
        </Text>

        <HStack spacing={2}>
          <When condition={googleDriveFile.webViewLink}>
            <Button
              colorScheme="primary"
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FcGoogle} />}
              onClick={() => window.open(googleDriveFile.webViewLink, '_blank')}
            >
              Apri in Google Drive
            </Button>
          </When>
        </HStack>
      </HStack>

      <SimpleGrid columns={2} spacing={4} p={2} templateColumns="300px 1fr">
        <Text fontWeight="medium">Nome</Text>
        <ValueField>{googleDriveFile.name}</ValueField>

        <Text fontWeight="medium">Visibilità</Text>
        <ValueField>{tFileVisibility(googleDriveFile.visibility)}</ValueField>
      </SimpleGrid>
    </Stack>
  );
};

const FILE_QUERY = graphql(`
  query FileDetailStaff($googleDriveFileId: String!) {
    googleDriveFile(id: $googleDriveFileId) {
      id
      name
      createDate
      webViewLink
      visibility
    }
  }
`);

export default Detail;
