import { MenuElement } from '@common/components/Navbar';

export const enum ROUTE {
  /* PUBLIC */
  OAUTH_CALLBACK = '/oauth/callback',
  OAUTH_SIGNIN = '/oauth/signin',
  IMPERSONATE = '/impersonate',

  /* PRIVATE */
  ACTIVITIES = '/attivita',
  ACTIVITIES_CREATE = '/attivita/crea',
  ACTIVITIES_DETAIL = '/attivita/:id',
  ACTIVITIES_EDIT = '/attivita/:id/modifica',

  STAMP = '/timbra',

  EXPIRATIONS = '/scadenze',
  EXPIRATIONS_DETAIL = '/scadenze/:id',

  FILES = '/documenti',
  FILES_DETAIL = '/documenti/:id',
}

export const menu: Array<MenuElement> = [
  {
    name: 'Attività',
    path: ROUTE.ACTIVITIES,
  },
  {
    name: 'Scadenze',
    path: ROUTE.EXPIRATIONS,
  },
  {
    name: 'Documenti',
    path: ROUTE.FILES,
  },
];
