import { FC, useRef } from 'react';
import { Case, Switch } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Center, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import Pagination from '@common/components/Pagination';
import { DEFAULT_PAGE_SIZE } from '@common/config/consts';
import { useFilters } from '@common/contexts/Filters';
import { usePagination } from '@common/contexts/Pagination';
import { safeDateFormat } from '@common/utils/formatters';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
import { ActivitySort } from '@gql/graphql';
import { ActivitiesFilters } from '../types';
import Filters from './Filters';

const List: FC = () => {
  const { page, setPage } = usePagination();
  const bodyRef = useRef<HTMLDivElement>(null);
  const { filters } = useFilters<ActivitiesFilters>();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(ACTIVITIES_QUERY, {
    variables: {
      pagination: {
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      filters: {
        month: filters.month || new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        service: filters.service,
        user: filters.user,
      },
      sort: ActivitySort.DateDesc,
    },
  });

  return (
    <VStack spacing={6} p={6} w="full" h="full">
      <Filters />

      <VStack spacing={4} justify="space-between" h="full" w="full" flexGrow={1} ref={bodyRef}>
        <Switch>
          <Case condition={!!error}>
            <Center h="full">Si è verificato errore imprevisto</Center>;
          </Case>

          <Case condition={!!loading}>
            <Loading />
          </Case>

          <Case condition={!data?.activityList.objects.length}>
            <Center h="full">Nessun risultato trovato</Center>
          </Case>

          <Case condition={!!data}>
            <TableContainer w="full">
              <Table variant="simple">
                <TableCaption>Totale ore: {data?.activityTotals?.hoursFormatted}</TableCaption>
                <Thead>
                  <Tr>
                    <Th w="20%">Cantiere</Th>
                    <Th w="20%">Utente</Th>
                    <Th>Data</Th>
                    <Th>Orario</Th>
                    <Th>Totale Ore</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data?.activityList.objects.map((activity) => (
                    <Tr
                      key={activity.id}
                      cursor="pointer"
                      _hover={{ bgColor: 'gray.100' }}
                      onClick={() => navigate(ROUTE.ACTIVITIES_DETAIL.replace(':id', activity.id.toString() || ''))}
                    >
                      <Td>{activity.service?.fullName}</Td>
                      <Td>{activity.user?.name}</Td>
                      <Td>{safeDateFormat(activity.date, 'd MMM yyyy')}</Td>
                      <Td>
                        {safeDateFormat(activity.date + 'T' + activity.startTime, 'H:mm')} -{' '}
                        {activity.endTime === activity.startTime ? '' : safeDateFormat(activity.date + 'T' + activity.endTime, 'H:mm')}
                      </Td>
                      <Td>{activity.totalHours}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Case>
        </Switch>

        <Pagination page={page} numPages={data?.activityList.numPages} total={data?.activityList.count} setPage={setPage} />
      </VStack>
    </VStack>
  );
};

const ACTIVITIES_QUERY = graphql(`
  query ActivityListEmployee($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {
    activityList(pagination: $pagination, filters: $filters, sort: $sort) {
      objects {
        id
        serviceId
        serviceUserId
        date
        startTime
        endTime
        isArchived
        createDate
        updateDate
        totalHours
        service {
          id
          name
          fullName
          clientId
          sectorCode
          users {
            name
          }
        }
        user {
          name
        }
      }
      count
      numPages
    }
    activityTotals(filters: $filters) {
      minutes
      hoursFormatted
    }
  }
`);

export default List;
