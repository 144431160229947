import React, { FC } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { When } from 'react-if';
import { Center, HStack, IconButton, Text } from '@chakra-ui/react';

interface Props {
  page: number;
  setPage: (page: number) => void;
  numPages?: number;
  total?: number;
}

const Pagination: FC<Props> = ({ page, setPage, numPages, total }) => {
  return (
    <Center position="relative" w="full">
      <HStack spacing={4}>
        <IconButton
          variant="link"
          aria-label="Pagina precedente"
          icon={<FiChevronLeft />}
          isDisabled={page === 1}
          onClick={() => setPage(page - 1)}
        />
        <Text fontWeight="medium">{page.toString()}</Text>
        <IconButton
          variant="link"
          aria-label="Pagina successiva"
          icon={<FiChevronRight />}
          isDisabled={page === numPages}
          onClick={() => setPage(page + 1)}
        />
      </HStack>

      <When condition={typeof total === 'number'}>
        <Text position="absolute" right={0} bottom={0} fontSize="sm" color="gray.500">
          {total?.toLocaleString()} risultati
        </Text>
      </When>
    </Center>
  );
};

export default Pagination;
