import { FC } from 'react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { FiCalendar, FiSearch, FiX } from 'react-icons/fi';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { Box, HStack, Icon, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import FilterInputField from '@common/components/FilterInputField';
import { useFilters } from '@common/contexts/Filters';
import { ExpirationsFilters } from '../types';

const Filters: FC = () => {
  const navigate = useNavigate();
  const { filters, push, remove } = useFilters<ExpirationsFilters>();

  return (
    <Box w="full">
      <HStack spacing={4}>
        <InputGroup>
          <FilterInputField
            name="name"
            value={filters.title}
            onChange={(v) => push('title', v)}
            placeholder="Cerca per titolo"
            icon={<Icon color="gray.500" as={FiSearch} />}
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon color="gray.500" as={FiCalendar} />
          </InputLeftElement>
          <RangeDatepicker
            selectedDates={filters.dateRange || []}
            propsConfigs={{ inputProps: { pl: 10, placeholder: 'Intervallo date' } }}
            onDateChange={(dates) => push('dateRange', dates)}
            configs={{
              firstDayOfWeek: 1,
              dateFormat: 'dd/MM/yyyy',
              dayNames: 'DLMMGVS'.split(''),
              monthNames: [
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre',
              ],
            }}
          />
          <When condition={!!filters.dateRange}>
            <InputRightElement pointerEvents="visible">
              <Icon
                color="gray.500"
                as={FiX}
                cursor="pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  remove('dateRange');
                }}
              />
            </InputRightElement>
          </When>
        </InputGroup>
      </HStack>
    </Box>
  );
};

export default Filters;
