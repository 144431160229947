import { FC, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Case, Switch } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Center, HStack, IconButton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import Loading from '@common/components/Loading';
import ValueField from '@common/components/ValueField';
import { useFilters } from '@common/contexts/Filters';
import { tFileVisibility } from '@common/utils/enumTranslate';
import { ROUTE } from '@config/routing';
import { graphql } from '@gql/gql';
// import { FileSort } from '@gql/graphql';
import { FileFilters } from '../types';
import Filters from './Filters';

interface GoogleDrivePagination {
  number: number;
  currentPageToken: string | null;
}

const List: FC = () => {
  const [pageTokes, setPageTokens] = useState<Array<string | null>>([null]);
  const [page, setPage] = useState<GoogleDrivePagination>({
    number: 1,
    currentPageToken: null,
  });

  const bodyRef = useRef<HTMLDivElement>(null);
  const { filters } = useFilters<FileFilters>();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(FILES_QUERY, {
    variables: {
      filters: {
        q: filters.q,
      },
      nextPageToken: page.currentPageToken,

      // filters: {
      //   name: filters.name,
      // },
      // sort: FileSort.CreateDate,
    },
  });

  return (
    <VStack spacing={6} p={6} w="full" h="full">
      <Filters />

      <VStack spacing={4} justify="space-between" h="full" w="full" flexGrow={1} ref={bodyRef}>
        <Switch>
          <Case condition={!!error}>
            <Center h="full">Si è verificato errore imprevisto</Center>;
          </Case>

          <Case condition={!!loading}>
            <Loading />
          </Case>

          <Case condition={!data?.googleDriveFileList.files.length}>
            <Center h="full">Nessun risultato trovato</Center>
          </Case>

          <Case condition={!!data}>
            <TableContainer w="full">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th w="20%">File</Th>
                    <Th w="100%">Visibilità</Th>
                    <Th>Data di creazione</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {data?.googleDriveFileList.files.map((file) => (
                    <Tr
                      key={file.id}
                      cursor="pointer"
                      _hover={{ bgColor: 'gray.100' }}
                      onClick={() => navigate(ROUTE.FILES_DETAIL.replace(':id', file.id.toString() || ''))}
                    >
                      <Td>
                        <ValueField>{file.name}</ValueField>
                      </Td>
                      <Td>{tFileVisibility(file.visibility)}</Td>
                      <Td>
                        <ValueField>{file.createDate && new Date(file.createDate).toLocaleDateString()}</ValueField>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Case>
        </Switch>

        <Center position="relative" w="full">
          <HStack spacing={4}>
            <IconButton
              variant="link"
              aria-label="Pagina precedente"
              icon={<FiChevronLeft />}
              isDisabled={page.number === 1 || loading}
              onClick={() => {
                setPage({
                  number: page.number - 1,
                  currentPageToken: pageTokes[page.number - 2],
                });

                setPageTokens(pageTokes.slice(0, page.number - 1));
              }}
            />
            <Text fontWeight="medium">{page.number.toString()}</Text>
            <IconButton
              variant="link"
              aria-label="Pagina successiva"
              icon={<FiChevronRight />}
              isDisabled={!data?.googleDriveFileList.nextPageToken || loading}
              onClick={() => {
                if (!data?.googleDriveFileList.nextPageToken) return;
                setPageTokens([...pageTokes, data?.googleDriveFileList.nextPageToken]);
                setPage({
                  number: page.number + 1,
                  currentPageToken: data?.googleDriveFileList.nextPageToken || null,
                });
              }}
            />
          </HStack>

          {/* <When condition={typeof total === 'number'}>
            <Text position="absolute" right={0} bottom={0} fontSize="sm" color="gray.500">
              {total?.toLocaleString()} risultati
            </Text>
          </When> */}
        </Center>
      </VStack>
    </VStack>
  );
};

const FILES_QUERY = graphql(`
  query FileListStaff($nextPageToken: String, $filters: GoogleDriveFileFilters) {
    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {
      nextPageToken
      files {
        id
        name
        createDate
        visibility
      }
    }
  }
`);

export default List;
