import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { FiltersProvider } from '@common/contexts/Filters';
import { PaginationProvider } from '@common/contexts/Pagination';
import Create from './Create';
import Detail from './Detail';
import Edit from './Edit';
import List from './List';
import { ActivitiesFilters } from './types';

const Activities: FC & {
  List: FC;
  Detail: FC;
  Create: FC;
  Edit: FC;
} = () => {
  return (
    <PaginationProvider>
      <FiltersProvider<ActivitiesFilters>
        paramStateOptions={{
          fields: ['service', 'type', 'dateRange', 'sector', 'user'],
        }}
      >
        <Outlet />
      </FiltersProvider>
    </PaginationProvider>
  );
};

Activities.List = List;
Activities.Detail = Detail;
Activities.Create = Create;
Activities.Edit = Edit;

export default Activities;
