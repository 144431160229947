import { createContext, useContext, useMemo } from 'react';
import useParamState from '../hooks/useParamState';

export const PAGE_ZERO = 1;

export interface PaginationContextState {
  page: number;
  setPage: (page: number) => void;
}

export const PaginationContext = createContext<PaginationContextState>({
  page: PAGE_ZERO,
  setPage: () => undefined,
});

export const usePagination = () => useContext(PaginationContext);

export const PaginationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [pagination, setPagination] = useParamState<{ page: number }>(
    { page: PAGE_ZERO },
    {
      fields: ['page'],
      transform: (values) => ({
        page: values.page ? parseInt(values.page, 10) : PAGE_ZERO,
      }),
    },
  );

  const context = useMemo<PaginationContextState>(
    () => ({ page: pagination?.page || PAGE_ZERO, setPage: (page) => setPagination({ page }) }),
    [pagination, setPagination],
  );

  return <PaginationContext.Provider value={context}>{children}</PaginationContext.Provider>;
};
