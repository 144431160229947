import { FC } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';
import { Button, Center, Icon, Image, Text, VStack } from '@chakra-ui/react';
import Logo from '@common/assets/img/logo.jpg';
import { LS_AUTH_KEY } from '@common/config/consts';
import apiClient from '@common/config/network';
import { useGoogleLogin } from '@react-oauth/google';

const CREATE_TOKEN_MUTATION = gql`
  mutation AccessTokenCreate($googleToken: String!) {
    accessTokenCreate(googleToken: $googleToken) {
      object {
        token
      }
      errors
    }
  }
`;
const OAuthSignin: FC = () => {
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: async (res) => {
      console.info('Logged in with code:', res);
      const tokenRes = await apiClient.query({
        query: CREATE_TOKEN_MUTATION,
        variables: { googleToken: res.access_token },
      });

      if (tokenRes.errors) {
        console.log('Error', tokenRes.errors);
        toast.error('Errore durante il login');
        return;
      }

      localStorage.setItem(LS_AUTH_KEY, tokenRes.data.accessTokenCreate.object.token);

      navigate('/');
    },
    flow: 'implicit',
  });

  return (
    <Center h="full">
      <VStack spacing={8}>
        <Image src={Logo} alt="Logo" width="400px" />

        <Text fontWeight="medium">Portale Dipendenti</Text>

        <Button
          variant="outline"
          p={7}
          fontSize="lg"
          fontWeight="medium"
          leftIcon={<Icon as={FcGoogle} fontSize="2xl" mr={2} />}
          onClick={() => login()}
        >
          Entra con Google
        </Button>
      </VStack>
    </Center>
  );
};

export default OAuthSignin;
