import { FC } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Box, HStack, Icon } from '@chakra-ui/react';
import FilterInputField from '@common/components/FilterInputField';
import { useFilters } from '@common/contexts/Filters';
import { FileFilters } from '../types';

const Filters: FC = () => {
  const navigate = useNavigate();
  const { push, filters } = useFilters<FileFilters>();

  return (
    <Box w="full">
      <HStack spacing={4}>
        <FilterInputField
          name="q"
          value={filters.q}
          onChange={(v) => push('q', v)}
          placeholder="Cerca per nome"
          icon={<Icon color="gray.500" as={FiSearch} />}
        />
      </HStack>
    </Box>
  );
};

export default Filters;
