import { When } from 'react-if';
import { Center, CircularProgress, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import BaseAutocompleteField from './BaseAutocompleteField';
import FakeInput from './FakeInput';
import { BaseAutocompleteProps } from './types';

interface Props<V> extends BaseAutocompleteProps<V> {
  isLoading?: boolean;
}

const Loader = <V extends string | number>({ isLoading, ...props }: Props<V>) => {
  if (isLoading || props.isDisabled)
    return (
      // @ts-expect-error inherit only styling props
      <FormControl isInvalid={!!props.error} {...props}>
        <When condition={!!props.label}>
          <FormLabel color={props.isDisabled ? 'blackAlpha.500' : 'inherit'} htmlFor={props.label}>
            {props.label}
          </FormLabel>
        </When>

        <FakeInput
          borderWidth={props.error ? 2 : 1}
          borderColor={props.isDisabled ? 'blackAlpha.200' : props.error ? 'red.400' : 'gray.200'}
          cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
          _hover={{
            borderColor: props.isDisabled ? 'gray.200' : 'gray.300',
          }}
        >
          <When condition={isLoading}>
          <Center w="full">
            <CircularProgress size={'25px'} isIndeterminate color="blackAlpha.400" />
          </Center>
          </When>
        </FakeInput>
        <When condition={!!props.helperText}>
          <FormHelperText>{props.helperText}</FormHelperText>
        </When>
        <When condition={!!props.error}>
          <FormErrorMessage>{props.error}</FormErrorMessage>
        </When>
      </FormControl>
    );
  else return <BaseAutocompleteField<V> {...props} />;
};

export default Loader;
