import { useEffect, useRef } from 'react';
import { GenericFunction } from '@types';

const useDebounce = (value: string, delay: number, onTimeout: GenericFunction) => {
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onTimeout(value);
    }, delay);
  }, [value, delay, onTimeout]);

  return timeout;
};

export default useDebounce;
